import { ChevronRight } from "@material-ui/icons";
import { Button } from "components/ThemedComponent/Common";
import React from "react";
import getEmployerLoginURL from "utils/getEmployerURL";
import { getUrlPath } from "utils/helpers";
import source from "utils/source";

const WantToHire: React.FC = () => {
	const handlePostJobClick = () => {
		import("../../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track("Employer login click", {
				page: getUrlPath(),
				Source: source,
				section: "Want to hire",
				actionType: "Redirection",
				cta: "Post job section",
			});
		});
		window.open(getEmployerLoginURL(), "_blank");
	};

	return (
		<div className="mx-auto max-w-screen-xl  px-4 pt-[72px] md:pt-[100px]">
			<div
				onClick={handlePostJobClick}
				className="flex cursor-pointer flex-col-reverse rounded-[24px] border bg-[#ee4] bg-gradient-to-r from-[#f6fdfb] to-[#ecfefa] px-4 md:flex-row  md:px-[80px]"
			>
				<div className="mx-auto mt-auto max-w-[400px]">
					<img
						className="object-contain object-bottom"
						src="https://storage.googleapis.com/mumbai_apnatime_prod/apna-home/two-people.png"
						alt="want to hire"
						loading="lazy"
					/>
				</div>
				<div className="mx-auto flex flex-col items-center py-[48px] md:ml-[100px] md:items-start md:py-[60px]">
					<h4 className="rounded bg-[#EAF8F4] px-4 py-2 text-md font-bold text-green-500 md:text-xl">
						APNA FOR EMPLOYERS
					</h4>
					<h3 className="mb-1 mt-8 text-[32px] font-bold text-green-700 md:text-[48px]">
						Want to hire?
					</h3>
					<h4 className="mb-0 mt-2 text-center text-md font-semibold leading-[24px] md:text-left">
						Find the best candidate from 5 crore+ active job seekers!
					</h4>
					<Button
						variant="primary"
						className="mt-6 !w-full !bg-white !font-semibold hover:!bg-green-500 md:mt-8 md:mt-[32px] md:max-w-[292px]"
						outline
						size="large"
					>
						Post job &nbsp; <ChevronRight fontSize="small" />
					</Button>
				</div>
			</div>
		</div>
	);
};

export default WantToHire;
