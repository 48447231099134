export const tickerData = [
	{
		id: 39394183,
		name: "Jafri Zain",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F39394183_1668008577927_ICON?alt=media",
		text: "Jafri Zain has fixed an interview",
		job_category_id: 37,
		hiring_state: "interview_fixed",
		updated: "today",
	},
	{
		id: 46148469,
		name: "Riya Singh",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F46148469_1687812889_ICON?alt=media",
		text: "Riya Singh has fixed an interview",
		job_category_id: 37,
		hiring_state: "interview_fixed",
		updated: "today",
	},
	{
		id: 18228447,
		name: "Suman Biswas",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F18228447_1632709469390_ICON?alt=media",
		text: "Suman Biswas got job 3 hours ago",
		job_category_id: 11,
		hiring_state: "hired",
		updated: "today",
	},
	{
		id: 35051403,
		name: "As Swathi",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F35051403_1655832443896_ICON?alt=media",
		text: "As Swathi has fixed an interview",
		job_category_id: 37,
		hiring_state: "interview_fixed",
		updated: "today",
	},
	{
		id: 30929093,
		name: "Zill Macwan",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F30929093_1688564265639_ICON?alt=media",
		text: "Zill Macwan got job 3 hours ago",
		job_category_id: 37,
		hiring_state: "hired",
		updated: "today",
	},
	{
		id: 25846304,
		name: "Foridul Islam (B.Com{Hons}, PGDCA)",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F25846304_1684897113867_ICON?alt=media",
		text: "Foridul got job 6 hours ago",
		job_category_id: 11,
		hiring_state: "hired",
		updated: "today",
	},
	{
		id: 3228180,
		name: "Dharmender Giri",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F3228180_1602860253762_ICON?alt=media",
		text: "Dharmender has fixed an interview",
		job_category_id: 29,
		hiring_state: "interview_fixed",
		updated: "today",
	},
	{
		id: 46643714,
		name: "Riya Comfort",
		photo_url:
			"https://storage.googleapis.com/mumbai_apnatime_prod/profile_photo%252FFF436248714_1688198150_ICON.jpg",
		text: "Riya Comfort has fixed an interview",
		job_category_id: 30,
		hiring_state: "interview_fixed",
		updated: "today",
	},
	{
		id: 46150516,
		name: "Vikash kumar",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F46150516_1687837334_ICON?alt=media",
		text: "Vikash kumar got job 4 hours ago",
		job_category_id: 11,
		hiring_state: "hired",
		updated: "today",
	},
	{
		id: 31272186,
		name: "Ashirwad Pattanayak",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F31272186_1649057788895_ICON?alt=media",
		text: "Ashirwad got job 8 hours ago",
		job_category_id: 21,
		hiring_state: "hired",
		updated: "today",
	},
	{
		id: 10858084,
		name: "Swarup DAS",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F10858084_1623357799849_ICON?alt=media",
		text: "Swarup DAS has fixed an interview",
		job_category_id: 11,
		hiring_state: "interview_fixed",
		updated: "today",
	},
	{
		id: 34080681,
		name: "Anmol Kasyap",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F34080681_1654057573174_ICON?alt=media",
		text: "Anmol Kasyap got job 5 hours ago",
		job_category_id: 30,
		hiring_state: "hired",
		updated: "today",
	},
	{
		id: 9874277,
		name: "Rohit kumar",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F9874277_1634265967621_ICON?alt=media",
		text: "Rohit kumar got job 2 hours ago",
		job_category_id: 5,
		hiring_state: "hired",
		updated: "today",
	},
	{
		id: 29986656,
		name: "Deepak Kumar shaw",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F29986656_1647135914331_ICON?alt=media",
		text: "Deepak Kumar got job 3 hours ago",
		job_category_id: 11,
		hiring_state: "hired",
		updated: "today",
	},
	{
		id: 19109134,
		name: "Ankur",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F19109134_1683369117657_ICON?alt=media",
		text: "Ankur has fixed an interview",
		job_category_id: 18,
		hiring_state: "interview_fixed",
		updated: "today",
	},
	{
		id: 45989600,
		name: "krishnesh pandey",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F45989600_1687244561_ICON?alt=media",
		text: "krishnesh pandey got job 5 hours ago",
		job_category_id: 37,
		hiring_state: "hired",
		updated: "today",
	},
	{
		id: 28598042,
		name: "Devendra Singh",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F28598042_1645449406806_ICON?alt=media",
		text: "Devendra Singh got job 2 hours ago",
		job_category_id: 48,
		hiring_state: "hired",
		updated: "today",
	},
	{
		id: 31591953,
		name: "jatin bhardwaj",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F31591953_1649712332467_ICON?alt=media",
		text: "jatin bhardwaj got job 5 hours ago",
		job_category_id: 12,
		hiring_state: "hired",
		updated: "today",
	},
	{
		id: 40055245,
		name: "Saloni Kondhalkar",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F40055245_1688118377_ICON?alt=media",
		text: "Saloni Kondhalkar has fixed an interview",
		job_category_id: 7,
		hiring_state: "interview_fixed",
		updated: "today",
	},
	{
		id: 14277007,
		name: "Suraj Pal Singh",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F14277007_1628908619127_ICON?alt=media",
		text: "Suraj Pal has fixed an interview",
		job_category_id: 30,
		hiring_state: "interview_fixed",
		updated: "today",
	},
	{
		id: 45885883,
		name: "MOHIT CHANDRESHBHAI BATAVIA",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F45885883_1686822618_ICON?alt=media",
		text: "Mohit has fixed an interview",
		job_category_id: 1038,
		hiring_state: "interview_fixed",
		updated: "today",
	},
	{
		id: 44656117,
		name: "Manoj gupta",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F44656117_1683990426689_ICON?alt=media",
		text: "Manoj gupta got job 2 hours ago",
		job_category_id: 11,
		hiring_state: "hired",
		updated: "today",
	},
	{
		id: 17363645,
		name: "Dipti Mandal",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F17363645_1661013983877_ICON?alt=media",
		text: "Dipti Mandal has fixed an interview",
		job_category_id: 10,
		hiring_state: "interview_fixed",
		updated: "today",
	},
	{
		id: 2382455,
		name: "Jaishankar",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F2382455_1636100749688_ICON?alt=media",
		text: "Jaishankar got job 4 hours ago",
		job_category_id: 52,
		hiring_state: "hired",
		updated: "today",
	},
	{
		id: 2982336,
		name: "Sathish V",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F2982336_1669566700129_ICON?alt=media",
		text: "Sathish V got job 5 hours ago",
		job_category_id: 11,
		hiring_state: "hired",
		updated: "today",
	},
	{
		id: 21034710,
		name: "Hasanur Piyada",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F21034710_1667285609380_ICON?alt=media",
		text: "Hasanur Piyada got job 3 hours ago",
		job_category_id: 11,
		hiring_state: "hired",
		updated: "today",
	},
	{
		id: 26208467,
		name: "Kajal Mandloi",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F26208467_1687880431718_ICON?alt=media",
		text: "Kajal Mandloi has fixed an interview",
		job_category_id: 37,
		hiring_state: "interview_fixed",
		updated: "today",
	},
	{
		id: 10852767,
		name: "Anil Kumar",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F10852767_1623344149693_ICON?alt=media",
		text: "Anil Kumar has fixed an interview",
		job_category_id: 29,
		hiring_state: "interview_fixed",
		updated: "today",
	},
	{
		id: 17867813,
		name: "M.Mahesh M.mahesh",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F17867813_1675592667841_ICON?alt=media",
		text: "M.Mahesh has fixed an interview",
		job_category_id: 37,
		hiring_state: "interview_fixed",
		updated: "today",
	},
	{
		id: 46248714,
		name: "SURENDRA KUMAR",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F46248714_1688198150_ICON?alt=media",
		text: "Surendra Kumar has fixed an interview",
		job_category_id: 30,
		hiring_state: "interview_fixed",
		updated: "today",
	},
	{
		id: 43789050,
		name: "Raman Mishra",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F43789050_1679317914_ICON?alt=media",
		text: "Raman Mishra has fixed an interview",
		job_category_id: 30,
		hiring_state: "interview_fixed",
		updated: "today",
	},
	{
		id: 12320030,
		name: "Sourav Pal",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F12320030_1626296634293_ICON?alt=media",
		text: "Sourav Pal has fixed an interview",
		job_category_id: 1014,
		hiring_state: "interview_fixed",
		updated: "today",
	},
	{
		id: 40360815,
		name: "Jenish Radadiya",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F40360815_1669968469454_ICON?alt=media",
		text: "Jenish Radadiya has fixed an interview",
		job_category_id: 7,
		hiring_state: "interview_fixed",
		updated: "today",
	},
	{
		id: 5017163,
		name: "Ajit Vetal",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F5017163_1608793085474_ICON?alt=media",
		text: "Ajit Vetal has fixed an interview",
		job_category_id: 37,
		hiring_state: "interview_fixed",
		updated: "today",
	},
	{
		id: 46373601,
		name: "Sharon Fernandes",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F46373601_1688648245_ICON?alt=media",
		text: "Sharon Fernandes has fixed an interview",
		job_category_id: 5,
		hiring_state: "interview_fixed",
		updated: "today",
	},
	{
		id: 26530357,
		name: "jesmitha yarraguntla",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F26530357_1665736003222_ICON?alt=media",
		text: "Jesmitha has fixed an interview",
		job_category_id: 5,
		hiring_state: "interview_fixed",
		updated: "today",
	},
	{
		id: 2090514,
		name: "Shahid Akhter",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F2090514_1618426684028_ICON?alt=media",
		text: "Shahid Akhter has fixed an interview",
		job_category_id: 11,
		hiring_state: "interview_fixed",
		updated: "today",
	},
	{
		id: 8776468,
		name: "Alishiba Arsud",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F8776468_1618906051922_ICON?alt=media",
		text: "Alishiba Arsud has fixed an interview",
		job_category_id: 10,
		hiring_state: "interview_fixed",
		updated: "today",
	},
	{
		id: 46353567,
		name: "Zakir Hussain",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F46353567_1688592018_ICON?alt=media",
		text: "Zakir Hussain got job 3 hours ago",
		job_category_id: 11,
		hiring_state: "hired",
		updated: "today",
	},
	{
		id: 45925707,
		name: "Deep Sahu",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F45925707_1686991420_ICON?alt=media",
		text: "Deep Sahu has fixed an interview",
		job_category_id: 30,
		hiring_state: "interview_fixed",
		updated: "today",
	},
	{
		id: 41412742,
		name: "Sohail akhter",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F41412742_1688195212_ICON?alt=media",
		text: "Sohail akhter got job 2 hours ago",
		job_category_id: 11,
		hiring_state: "hired",
		updated: "today",
	},
	{
		id: 46113120,
		name: "Sandeep jakhar",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F46113120_1687693376_ICON?alt=media",
		text: "Sandeep jakhar has fixed an interview",
		job_category_id: 30,
		hiring_state: "interview_fixed",
		updated: "today",
	},
	{
		id: 9063215,
		name: "Md Azeem",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F9063215_1619661125897_ICON?alt=media",
		text: "Md Azeem got job 4 hours ago",
		job_category_id: 1014,
		hiring_state: "hired",
		updated: "today",
	},
	{
		id: 40325339,
		name: "Nitu Tiwari",
		photo_url:
			"https://firebasestorage.googleapis.com/v0/b/mumbai_apnatime_prod/o/profile_photo%2F40325339_1669710461810_ICON?alt=media",
		text: "Nitu Tiwari has fixed an interview",
		job_category_id: 37,
		hiring_state: "interview_fixed",
		updated: "today",
	},
];
