type SuccessHandler = (coords: { lat: number; lng: number }) => void;
type ErrorHandler = (message: string) => void;

/** Sample usage:
 *     fetchGeolocation(
 *       (points) => { console.log("Points", points.lat, points.lng); },
 *       (err) => { console.log("Error", err); }
 *    );
 */
// eslint-disable-next-line import/prefer-default-export
export const fetchGeolocation = (onSuccess: SuccessHandler, onError: ErrorHandler) => {
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(
			(arg) => {
				onSuccess({ lat: arg.coords.latitude, lng: arg.coords.longitude });
			},
			(err) => {
				onError(err.message);
			}
		);
	} else {
		onError("Geolocation is not supported by this browser");
	}
};
