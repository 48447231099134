/* eslint-disable react/jsx-props-no-spreading */
const LoaderAnimated = (props) => (
	<svg
		viewBox="0 0 100 100"
		preserveAspectRatio="xMidYMid"
		style={{ width: "100%", height: "auto" }}
		{...props}
	>
		<circle cx="84" cy="50" r="2.76812" fill="#fc9f5b">
			<animate
				attributeName="r"
				repeatCount="indefinite"
				dur="0.625s"
				calcMode="spline"
				keyTimes="0;1"
				values="10;0"
				keySplines="0 0.5 0.5 1"
				begin="0s"
			/>
			<animate
				attributeName="fill"
				repeatCount="indefinite"
				dur="2.5s"
				calcMode="discrete"
				keyTimes="0;0.25;0.5;0.75;1"
				values="#fc9f5b;#9691cb;#83bde4;#ffd166;#fc9f5b"
				begin="0s"
			/>
		</circle>
		<circle cx="40.5884" cy="50" r="10" fill="#fc9f5b">
			<animate
				attributeName="r"
				repeatCount="indefinite"
				dur="2.5s"
				calcMode="spline"
				keyTimes="0;0.25;0.5;0.75;1"
				values="0;0;10;10;10"
				keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
				begin="0s"
			/>
			<animate
				attributeName="cx"
				repeatCount="indefinite"
				dur="2.5s"
				calcMode="spline"
				keyTimes="0;0.25;0.5;0.75;1"
				values="16;16;16;50;84"
				keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
				begin="0s"
			/>
		</circle>
		<circle cx="74.5884" cy="50" r="10" fill="#ffd166">
			<animate
				attributeName="r"
				repeatCount="indefinite"
				dur="2.5s"
				calcMode="spline"
				keyTimes="0;0.25;0.5;0.75;1"
				values="0;0;10;10;10"
				keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
				begin="-0.625s"
			/>
			<animate
				attributeName="cx"
				repeatCount="indefinite"
				dur="2.5s"
				calcMode="spline"
				keyTimes="0;0.25;0.5;0.75;1"
				values="16;16;16;50;84"
				keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
				begin="-0.625s"
			/>
		</circle>
		<circle cx="16" cy="50" r="0" fill="#83bde4">
			<animate
				attributeName="r"
				repeatCount="indefinite"
				dur="2.5s"
				calcMode="spline"
				keyTimes="0;0.25;0.5;0.75;1"
				values="0;0;10;10;10"
				keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
				begin="-1.25s"
			/>
			<animate
				attributeName="cx"
				repeatCount="indefinite"
				dur="2.5s"
				calcMode="spline"
				keyTimes="0;0.25;0.5;0.75;1"
				values="16;16;16;50;84"
				keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
				begin="-1.25s"
			/>
		</circle>
		<circle cx="16" cy="50" r="7.23188" fill="#9691cb">
			<animate
				attributeName="r"
				repeatCount="indefinite"
				dur="2.5s"
				calcMode="spline"
				keyTimes="0;0.25;0.5;0.75;1"
				values="0;0;10;10;10"
				keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
				begin="-1.875s"
			/>
			<animate
				attributeName="cx"
				repeatCount="indefinite"
				dur="2.5s"
				calcMode="spline"
				keyTimes="0;0.25;0.5;0.75;1"
				values="16;16;16;50;84"
				keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
				begin="-1.875s"
			/>
		</circle>
	</svg>
);

export default LoaderAnimated;
