import QueriesKeys from "constants/queryKeys";
import { useQuery } from "react-query";
import { getNearestGioLocation } from "utils/apiClientPrivate";

export type GeoLocationCords = {
	latitude?: string;
	longitude?: string;
};

export type NearestLocationResponseTypes = {
	data: LocationDetails;
	success: Boolean;
};
export type LocationDetails = {
	city: City;
	nearest_area: NearestAreaType;
};

export type City = {
	name: String;
	id: String;
	status: number;
};

export type NearestAreaType = {
	id: number | string;
	latitude: number;
	longitude: number;
	name: string;
};

const useNearestJobLocation = (locationData: GeoLocationCords) =>
	useQuery<NearestLocationResponseTypes>(
		[QueriesKeys.GET_NEAREST_LOCATION, locationData.latitude],
		async () => {
			const { data } = await getNearestGioLocation(locationData);
			return data;
		},
		{
			enabled: !!locationData.latitude,
			retry: false,
		}
	);
export default useNearestJobLocation;
