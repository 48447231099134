import { ChevronRight } from "@material-ui/icons";
import Marquee from "components/Marquee/Marquee";
import { Button } from "components/ThemedComponent/Common";
import Image from "next/image";
import Link from "next/link";
import React, { useState } from "react";
import { bp, bpMax, styled } from "theme";
import { getUrlPath } from "utils/helpers";
import jobDetails from "../../cms/category-data";

const colors = ["#512194", "#9B0000", "#005F3E", "#AA4900", "#004BA9", "#A42700"];

const Item = ({ title, icon, openings, index = 0, slug, id }) => {
	const color = colors[index % colors.length];
	const link = `jobs/${slug}-jobs`;

	const trackChipClick = () => {
		import("../../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track("Job Category Chip Click", {
				title,
				page: getUrlPath(),
			});
		});
	};

	return (
		<Link href={link} passHref>
			<StyledItem
				href={link}
				color={color}
				onClick={trackChipClick}
				className="mr-4 flex w-[292px] shrink-0 cursor-pointer rounded-xl border from-[#fff] to-[#F1EAFA] p-4 transition-all duration-500 md:hover:border-[#722ED1] md:hover:bg-gradient-to-r"
			>
				<div className="mr-3 h-[40px] w-[40px] shrink-0 rounded-full bg-[#F1EAFA] grayscale">
					<Image
						src={
							icon ||
							"https://cdn.apna.co/employerDashboard_FE/department-icons/Admin%20%3A%20Back%20Office%20%3A%20Computer%20Operator.svg"
						}
						alt={title}
						layout="fixed"
						width={40}
						height={40}
						loading="eager"
						priority={false}
						quality={50}
						objectFit="contain"
					/>
				</div>
				<div className="grow overflow-hidden">
					<div className="flex grow items-center">
						<p className="m-0 overflow-hidden text-ellipsis whitespace-nowrap text-left text-sm font-medium md:text-[16px]">
							{title}
						</p>
						<ChevronRight
							fill="currentColor"
							fontSize="small"
							className="ml-auto inline"
						/>
					</div>

					<p className="mb-0  overflow-hidden text-ellipsis whitespace-nowrap text-left text-sm text-[#8C8594] md:text-md">
						{(openings as number).toLocaleString()} openings
					</p>
				</div>
			</StyledItem>
		</Link>
	);
};

const StyledItem = styled.a`
	${({ color }) => `
		${bp.sm} {
		&:hover {
			background: linear-gradient(to right, #fff, ${color}10);
			border: 1px solid ${color};
			color: ${color};
			p {
				color: ${color};
			}
		}
		}
		`}
`;

const TrendingRoles: React.FC = () => {
	const [data] = useState(() =>
		(jobDetails as [])
			.sort(
				// Sort jobs in descending order of demand
				(a, b) => b.job_count.total_jobs - a.job_count.total_jobs
			)
			.filter((i) => i.job_count.total_jobs > 0)
			// .slice(0, 40)
			.map((i) => ({
				title: i.type,
				openings: i.job_count.total_jobs,
				icon: roleIdToDepartmentIdMapping[i.id]
					? `https://storage.googleapis.com/mumbai_apnatime_prod/department_icons/${
							roleIdToDepartmentIdMapping[i.id]
					  }.svg`
					: null,
				slug: i.slug,
				id: i.id,
			}))
	);

	const handleViewAllClick = () => {
		import("../../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track("Trending Jobs View All Clicked", {
				page: getUrlPath(),
				screen: "HomePage",
			});
		});
	};

	return (
		<div className="px-4 py-[148px] text-center">
			<h2 className="text-center text-[32px] font-bold leading-[48px]">
				Trending job roles on Apna
			</h2>
			<div className="-mx-4 hidden overflow-hidden md:block">
				<Marquee stopOnHover speed={150} className="-mx-4 mt-[54px] md:mt-[72px]">
					{data.slice(0, data.length / 2).map((i, index) => (
						<Item {...i} index={index} />
					))}
				</Marquee>

				<Marquee stopOnHover speed={150} className="-ml-[82px] -mr-[182px] mt-5">
					{data.slice(data.length / 2).map((i, index) => (
						<Item {...i} index={index} />
					))}
				</Marquee>
			</div>

			<div className="block md:hidden">
				<Marquee stopOnHover speed={150} className="-mx-4 mt-[54px] md:mt-[72px]">
					{data.slice(0, data.length / 3).map((i, index) => (
						<Item {...i} index={index} />
					))}
				</Marquee>

				<Marquee stopOnHover speed={150} className="-mx-4 -ml-[186px] mt-5">
					{data
						.slice(data.length / 3, (data.length * 2) / 3)
						.map((i, index) => (
							<Item {...i} index={index} />
						))}
				</Marquee>

				<Marquee stopOnHover speed={150} className="-mx-4 mt-5">
					{data.slice((data.length * 2) / 3).map((i, index) => (
						<Item {...i} index={index} />
					))}
				</Marquee>
			</div>

			<Link href="/job-search?keyword=Jobs+By+Department" passHref>
				<Button
					as="a"
					variant="primary"
					className="!mx-auto mt-[54px] !h-[56px] !w-full !font-semibold md:mt-[72px] md:max-w-[292px]"
					outline
					size="large"
					onClick={handleViewAllClick}
				>
					View all &nbsp; <ChevronRight fontSize="small" />
				</Button>
			</Link>
		</div>
	);
};

export default TrendingRoles;

const roleIdToDepartmentIdMapping = {
	"35": 41,
	"64": 40,
	"11": 12,
	"33": 22,
	"5": 18,
	"1009": 19,
	"37": 31,
	"1015": 42,
	"13": 12,
	"2": 1,
	"1": 18,
	"26": 26,
	"16": 42,
	"4": 5,
	"10": 2,
	"30": 35,
	"18": 32,
	"27": 1,
	"9": 40,
	"7": 1,
	"1014": 42,
	"34": 40,
	"20": 17,
	"1016": 23,
	"52": 24,
	"8": 8,
	"3": 3,
	"1010": 28,
	"29": 33,
	"36": 38,
	"19": 32,
	"32": 32,
	"51": 22,
	"1038": 39,
	"23": 14,
	"58": 15,
	"21": 21,
	"62": 14,
	"17": 15,
	"66": 3,
};
